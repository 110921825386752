/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-07-13",
    versionChannel: "nightly",
    buildDate: "2023-07-13T00:20:50.346Z",
    commitHash: "e0886707c5b9637c3fb6d3064a6ffd2cb28117de",
};
